/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.font-good-times {
  font-family: "good-times", sans-serif;
}

.font-gill-sans-nova {
  font-family: "gill-sans-nova", sans-serif;
  font-weight: 300;
}

.eb-button {
  background-image: url("/assets/images/eb-button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.lazyload, .lazyloading, .lazyloaded {
  transition: opacity 0.1s ease-in;
}

.lazyload, .lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
}
